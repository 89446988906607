/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import type { StackProps } from '@chakra-ui/react';
import { HStack, Icon, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { service, useNetwork } from '@kiroboio/fct-sdk';
import { Chart21, Setting3, TickCircle, Timer } from 'iconsax-react';
// import { usePathname } from 'next/navigation';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useEffect, useRef, useState } from 'react';
// import { useLocalStorage } from 'react-use';
import { FeatherTurtle } from '~/assets/FeatherTurtle';
import { HookedNumberInput } from '~/lib/components/NumberInput';
import { useGetPaymentPerPayer } from '../../Provider/hooks/useGetPaymentPerPayer';
import { useMSCallOptions } from '../../Provider/hooks/useMSCallOptions';

import { useTranslations } from 'next-intl';
import { formatUsdService } from '~/lib/utils/number';
import { GasOption } from './GasOption';
// import { getAccountsFromNamespaces } from '@walletconnect/utils';

const formatGWei = (value: string) =>
  service.formatting.prebuild.formatValue({
    service: 'gas',
    name: 'gas-price',
    decimals: 9,
    digits: 4,
    value,
  });

export const GasDetailsAdvanced: FC<
  StackProps & {
    isMagicExist: boolean;
    isDisabled?: boolean;
    gasOption: string;
    setGasOption: Dispatch<SetStateAction<string>>;
  }
> = ({ isMagicExist, isDisabled, gasOption, setGasOption }) => {
  const [customClicked, setCustomClicked] = useState(false);
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const { data: networkData } = useNetwork();

  // const pathname = usePathname();
  // const pathArray = pathname.split('/');
  // const projectId = pathArray[2];
  // const [value, _setValue] = useLocalStorage(
  //   `ki-selected-gas-option[${projectId ?? ''}]`,
  //   ''
  // );

  const {
    msCallOptions,
    update,
    form: {
      formState: { errors },
    },
  } = useMSCallOptions();

  const timeToStart = Math.round(
    Math.max(0, Number(msCallOptions.validFrom) - new Date().getTime() / 1000)
  );

  const duration = Math.round(
    Math.max(
      0,
      Number(msCallOptions.expiresAt) - Number(msCallOptions.validFrom)
    )
  );

  const {
    minGasPrice,
    gasPrice: {
      fastest: { maxFeePerGas },
    },
    maxGasPrice,
  } = networkData.raw;

  const maxFeePerGasMin = Math.max(
    Math.min(Number(maxFeePerGas), Number(maxGasPrice)),
    Number(minGasPrice)
  );

  const flexibleTimingWei = Math.max(
    Number(minGasPrice),
    Math.min(
      Number(maxFeePerGasMin) +
        (timeToStart * 1e9) / 3600 -
        (duration * 1e9) / 3600,
      Number(maxGasPrice)
    )
  ).toString();

  const slowGasPrice = Number(flexibleTimingWei);

  const fastGasPrice = Math.round(
    Math.min(
      Number(maxFeePerGasMin) +
        (timeToStart * 1e9) / 3600 +
        (duration * 1e9) / 3600,
      Number(maxGasPrice)
    )
  );

  // const gasPrice = +msCallOptions.maxGasPrice;
  // value === '1'
  // ? slowGasPrice
  // : value === '4'
  // ? +msCallOptions.maxGasPrice
  // : fastGasPrice;

  const immediateOrSoonWei = fastGasPrice.toString();

  const { converted } = useGetPaymentPerPayer({
    customGasPrice: +msCallOptions.maxGasPrice,
    slowGasPrice,
    fastGasPrice,
  });

  // const {
  // minGasPrice: minGasPriceFmt,
  // gasPrice: {
  // fastest: { maxFeePerGas: maxFeePerGasFmt },
  // },
  // maxGasPrice: maxGasPriceFmt,
  // } = networkData.fmt;

  const flexibleTimingGwei = formatGWei(flexibleTimingWei);

  const immediateOrSoonGwei = formatGWei(immediateOrSoonWei);

  useEffect(() => {
    if (customClicked) {
      initialFocusRef.current?.focus();
    }
  }, [customClicked]);

  // const gasTracker = useGasTracker();

  useEffect(() => {
    if (isMagicExist) {
      update({ maxGasPrice: '100000000000' });
    }
  }, [isMagicExist]);

  const setValue = (value: string) => {
    if (isMagicExist) {
      return;
    }
    setGasOption(value);
    const map: Record<string, string> = {
      1: flexibleTimingWei,
      2: immediateOrSoonWei,
      // 3: futureProofWei,
    };
    if (value === '4') {
      setCustomClicked(true);
      return;
    }
    value && update({ maxGasPrice: map[value] });
    setCustomClicked(false);
  };

  useEffect(() => {
    if (gasOption) {
      setValue(gasOption);
    }
  }, [gasOption]);

  // TODO: fix this, cause infinite loop - maybe we don't need this
  // useLayoutEffect(() => {
  //   if (value) {
  //     setValue(value);
  //   } else {
  //     update({ maxGasPrice: '' }, false);
  //     clearErrors('maxGasPrice');
  //   }
  // }, [setValue, update, clearErrors]);
  const t = useTranslations();

  if (isMagicExist)
    return (
      <Stack justify="center" spacing="0" gap="32px">
        (
        <HStack ml="-3px">
          <Icon
            as={TickCircle}
            color="green.500"
            boxSize="22px"
            variant="Bold"
          />
          <Text fontWeight="semibold">{t('execution-is-possible-now')}</Text>
        </HStack>
      </Stack>
    );

  return (
    <Stack justify="center" spacing="0" gap="24px" px="10px">
      <Text ml="15px" fontWeight="semibold">
        {t('select-max-fee')}
      </Text>

      <RadioGroup onChange={setValue} value={gasOption}>
        <Stack>
          <Stack
            spacing="0"
            rounded="16px"
            border="1px"
            borderColor={
              errors.maxGasPrice && !customClicked
                ? 'orange.500'
                : 'transparent'
            }
          >
            <GasOption
              isDisabled={isDisabled}
              isChecked={gasOption === '1'}
              title={t('flexible-timing')}
              value="1"
              icon={<FeatherTurtle />}
              usd={`$${formatUsdService(converted.slow)}`}
              gwei={flexibleTimingGwei}
              description={t('use-this-when-exact-timing-is-not-important')}
            />
            <GasOption
              isDisabled={isDisabled}
              data-cy="pick-current-gas"
              isChecked={gasOption === '2'}
              title={t('immediate-when-possible')}
              value="2"
              icon={
                <Icon
                  as={Chart21}
                  boxSize="6"
                  color="primary.500"
                  variant="Bold"
                />
              }
              usd={`$${formatUsdService(converted.fast)}`}
              gwei={immediateOrSoonGwei}
              description={t(
                'will-run-when-conditions-are-met-and-network-fees-do-not-exceed-immediateorsoongwei-gwei',
                { gwei: immediateOrSoonGwei }
              )}
            />
            {/*
            <GasOption
              isDisabled={isDisabled}
              isChecked={value === '3'}
              title="Future Proof"
              value="3"
              icon={<FeatherCheetah />}
              usd={`$${formatUsdService(converted.max)}`}
              gwei={futureProofGwei}
              description="
                Will run when conditions are met, regardless of network load
              "
            />
            */}
            <GasOption
              isDisabled={isDisabled}
              isChecked={gasOption === '4'}
              title={t('custom')}
              value="4"
              icon={
                <Icon
                  as={Setting3}
                  boxSize="6"
                  color="gray.500"
                  variant="Bold"
                />
              }
              usd={
                customClicked ? `$${formatUsdService(converted.custom)}` : ''
              }
              gwei={
                customClicked
                  ? (+msCallOptions.maxGasPrice * 1e-9).toFixed(2)
                  : ''
              }
              description={
                <Stack py="2" px="4">
                  <Text fontSize="sm" color="gray.400">
                    {t('set-max-gas-price-max-gwei')}{' '}
                  </Text>
                  <HookedNumberInput
                    ref={initialFocusRef}
                    disabled={isDisabled}
                    autoFocus={gasOption === '4'}
                    hookParams={{
                      type: 'wei',
                      decimals: 9,
                      value: String(msCallOptions.maxGasPrice),
                      onChange: (value) => {
                        update({ maxGasPrice: value });
                      },
                      onFocus: () => {
                        initialFocusRef.current?.select();
                      },
                    }}
                    suffix=" gwei"
                    variant="filled"
                    // bg="whiteAlpha.100"
                    rounded="12px"
                    size="md"
                    placeholder="gwei"
                    _placeholder={{ color: 'gray.500' }}
                    textAlign="center"
                    zIndex="1"
                    w="full"
                    h="42px"
                  />
                </Stack>
              }
            />
          </Stack>
        </Stack>

        {errors.maxGasPrice && (
          <Text
            textAlign="center"
            pt="4"
            color="orange.500"
            fontSize="14px"
            fontWeight="400"
          >
            {t('please-select-max-fee-gas-price')}
          </Text>
        )}
      </RadioGroup>

      {msCallOptions.maxGasPrice && (
        <Stack pl="15px">
          {converted.customCanRunNow ? (
            <HStack ml="-3px">
              <Icon
                as={TickCircle}
                color="green.500"
                boxSize="22px"
                variant="Bold"
              />
              <Text fontWeight="semibold">
                {t('execution-is-possible-now')}
              </Text>
            </HStack>
          ) : (
            <Stack ml="-3px">
              <HStack>
                <Icon as={Timer} color="orange.500" variant="Bold" />
                <Text fontWeight="semibold">Your Intent will be delayed</Text>
              </HStack>
              <Text fontSize="sm" color="gray.400">
                {t(
                  'after-you-sign-the-execution-of-the-intent-will-be-delayed-until-the-network-fees-are-lower-than-the-max-fee-you-set'
                )}
              </Text>
              {/*
              <HStack>
                <Text fontSize="sm">
                  Current:{' '}
                  <Text as="span" fontWeight="semibold">
                    {immediateOrSoonGwei}
                    <Text as="span" fontSize="xs">
                      {' GWEI'}
                    </Text>
                  </Text>
                </Text>
                <Text fontSize="xs" color="gray.500">
                  |
                </Text>
                <Text fontSize="sm">
                  Your selection:{' '}
                  <Text as="span" fontWeight="semibold">
                    {toMaxFixed(
                      +ethers.utils.formatUnits(
                        msCallOptions.maxGasPrice || '0',
                        'gwei'
                      ),
                      2,
                      { useGrouping: true }
                    )}
                    <Text as="span" fontSize="xs">
                      {' GWEI'}
                    </Text>
                  </Text>
                </Text>
              </HStack>
              */}
            </Stack>
          )}
          {converted.customCanRunNow && (
            <Text fontSize="sm">
              {t.rich('payment-upon-immediate-execution', {
                // eslint-disable-next-line react/no-unstable-nested-components
                amount: () => (
                  <Text
                    fontSize="md"
                    as="span"
                    fontWeight="semibold"
                    color="green.400"
                  >
                    {`$${formatUsdService(converted.customNow)}`}
                  </Text>
                ),
              })}
            </Text>
          )}
        </Stack>
      )}
    </Stack>
  );
};
