export const MaxGasSettings = () => <>MaxGasSettings</>;
/* eslint-disable @typescript-eslint/no-unused-vars */
// import type { StackProps } from '@chakra-ui/react';
// import {
//   HStack,
//   InputGroup,
//   InputRightElement,
//   Spinner,
//   Stack,
//   Tag,
//   Text,
// } from '@chakra-ui/react';
// import { t } from 'i18next';
// import type { FC } from 'react';
// import { useNetwork } from 'src/services/methods/useNetworkQuery';
//
// import { useMSCallOptions } from '../../Provider/hooks/useMSCallOptions';
// import { HookedNumberInput } from '~/lib/components/NumberInput';
//
// import { InfoPopover } from './InfoPopover';
//
// export const MaxGasSettings: FC<StackProps> = (props) => {
//   const {
//     msCallOptions,
//     calcGasAutomatically,
//     setCalcGasAutomatically,
//     update,
//     form: {
//       formState: { errors },
//     },
//   } = useMSCallOptions();
//
//   const recommendedGasQuery = useNetwork();
//
//   return (
//     <Stack>
//       <Stack spacing="0">
//         <HStack>
//           <HStack spacing="0" gap="10px">
//             <Text fontWeight="semibold">{t('maxGas')}</Text>
//             <InfoPopover description={t('maxGasPriceForFlow')} />
//           </HStack>
//           {recommendedGasQuery.isFetching && <Spinner size="sm" />}
//         </HStack>
//       </Stack>
//
//       <InputGroup>
//         <HookedNumberInput
//           hookParams={{
//             type: 'wei',
//             decimals: 9,
//             value: msCallOptions.maxGasPrice,
//             onChange: (value) => {
//               setCalcGasAutomatically(false);
//               update({ maxGasPrice: value });
//             },
//           }}
//           variant="filled"
//           bg="whiteAlpha.100"
//           rounded="18px"
//           h="55px"
//         />
//         <InputRightElement h="full" minW="136px">
//           <HStack rounded="full" pl="3">
//             <Text fontSize="sm">GWEI</Text>
//             <Tag
//               rounded="full"
//               onClick={() => {
//                 setCalcGasAutomatically(true);
//                 update({
//                   maxGasPrice:
//                     recommendedGasQuery.data?.formatted.recommendedGasBL.toString(),
//                 });
//               }}
//               cursor="pointer"
//               colorScheme={calcGasAutomatically ? 'primary' : 'gray'}
//               opacity={calcGasAutomatically ? 1 : 0.5}
//             >
//               Auto
//             </Tag>
//           </HStack>
//         </InputRightElement>
//       </InputGroup>
//       {errors.maxGasPrice && (
//         <Text color="orange.500" fontSize="sm">
//           {errors.maxGasPrice.message}
//         </Text>
//       )}
//     </Stack>
//   );
// };
