/* eslint-disable jsx-a11y/anchor-is-valid */
import type { ButtonProps } from '@chakra-ui/react';
import { Button, Icon } from '@chakra-ui/react';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import 'highlight.js/styles/atom-one-dark.css';
import { CodeCircle } from 'iconsax-react';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';

import { useFCTStore } from '~/lib/components/builder/Provider/FCTStore';
import { PUBLISH_BLOCKING_QUERY_KEY } from '~/lib/components/builder/Provider/utils';
import {
  flowErrorsId,
  sdkCodeId,
  useFlowErrorsModalActions,
  useGetSDKCodeModalActions,
} from '~/lib/hooks/useModals/useComplexModals';

export const SDKCodeButton: FC<ButtonProps> = () => {
  // const { meta, ...sdkCodeModal } = useSDKCodeModal();
  // const { onOpen: onOpenSDKCode, onClose: onCloseSDKCode } =
  //   useGetSDKCodeModal();
  const { open: onOpenSDKCode, close } = useGetSDKCodeModalActions(sdkCodeId);

  const handleClose = useCallback(() => {
    close({});
  }, [close]);

  const handleOpenSDKCode = useCallback(() => {
    onOpenSDKCode({});
  }, [onOpenSDKCode]);

  // const errorsModal = useDisclosure();
  // const { onOpen: onOpenErrors } = useFlowErrorsModal();
  const { open: openFlowErrors } = useFlowErrorsModalActions(flowErrorsId);

  const handleOpenFlowErrors = useCallback(() => {
    openFlowErrors({});
  }, [openFlowErrors]);

  const isLoading = useIsFetching({ queryKey: [PUBLISH_BLOCKING_QUERY_KEY] });

  useEffect(() => {
    return () => {
      handleClose();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryClient = useQueryClient();
  const cache = queryClient.getQueryCache();

  const hasQueriesErrors = cache
    .getAll()
    .filter(
      (q) => q && q.queryKey && q.queryKey.includes(PUBLISH_BLOCKING_QUERY_KEY)
    )
    .some((q) => q.state.status === 'error');

  const handleOpen = () => {
    const valid = useFCTStore.getState().validatorSlice.validateAll();
    const connectionErrors = useFCTStore
      .getState()
      .nodeStates.hasUnreachables();
    if (connectionErrors || hasQueriesErrors || !valid) {
      handleOpenFlowErrors();
    } else {
      handleOpenSDKCode();
    }
  };

  const isDisabled = useFCTStore(
    (state) => state.nodes.length < 2 || state.edges.length < 1
  );
  const t = useTranslations();

  return (
    <Button
      data-cy="fct-sdk-code-button"
      leftIcon={<Icon as={CodeCircle} color="blue.500" boxSize="24px" />}
      zIndex={98}
      isDisabled={isLoading > 0 || isDisabled}
      colorScheme="gray"
      rounded="xl"
      bg="#DFDFDF"
      color="black"
      _hover={{ bg: 'blackAlpha.100', _dark: { bg: 'whiteAlpha.100' } }}
      _dark={{ bg: '#202020C0', color: 'white' }}
      p="4"
      py="3"
      onClick={handleOpen}
    >
      {t('get-sdk-code')}{' '}
    </Button>
  );
};
