import type { StackProps } from '@chakra-ui/react';
import {
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
// import { t } from 'i18next';
import moment from 'moment';
import type { FC } from 'react';
// import { useEffect } from 'react';
import type { FieldValues, FormState, UseFormRegister } from 'react-hook-form';

// import { useAccount } from 'src/kirobo';
// import { useGasTracker } from 'src/services/methods/useNetworkQuery';
// import { useProjectQuery } from '../../Provider/hooks/projectQueries/useProjectQuery';
import { useMSCallOptions } from '../../Provider/hooks/useMSCallOptions';

import { useTranslations } from 'next-intl';
import { InfoPopover } from './InfoPopover';
import { NetworkInfo } from './NetworkInfo';
import type { NotifyFormFields } from './useNotifyForm';

interface FCTNotifySettingsProps extends StackProps {
  formState: FormState<FieldValues>;
  register: UseFormRegister<NotifyFormFields>;
}

export const FCTNotifySettings: FC<FCTNotifySettingsProps> = ({
  formState,
  register,
  ...props
}) => {
  const {
    msCallOptions,
    update,
    form: {
      formState: { errors },
    },
  } = useMSCallOptions();

  // const {
  //   wei: { max: defaultMaxGas },
  // } = useGasTracker();

  // const { data: selectedProject } = useProjectQuery();
  // const {
  //   fct: {
  //     analytics: { fctConfig },
  //   },
  // } = useAccount();

  // useEffect(() => {
  //   update({ name: selectedProject?.name, maxGasPrice: defaultMaxGas });
  //   fctConfig.setFctId(selectedProject?.id || '');
  // }, [selectedProject, update, defaultMaxGas, fctConfig]);

  const validFrom = moment
    .unix(Number(msCallOptions.validFrom))
    .format('YYYY-MM-DDTHH:mm');
  const expiresAt = moment
    .unix(Number(msCallOptions.expiresAt))
    .format('YYYY-MM-DDTHH:mm');

  const isTelegramOrWebhookMissingError = formState.errors.obj;
  // console.log('isTelegramOrWebhookMissingError:', isTelegramOrWebhookMissingError);

  const t = useTranslations();
  return (
    <Stack align="center">
      <NetworkInfo />
      <HStack
        padding="30px"
        // shadow="2xl"
        border="1px"
        bg="blackAlpha.50"
        borderColor="blackAlpha.50"
        _dark={{ borderColor: 'whiteAlpha.50', bg: 'whiteAlpha.50' }}
        rounded="3xl"
        w="1050px"
        spacing="50px"
        align="flex-start"
        justify="space-between"
      >
        <Stack flex="1" spacing="32px" {...props}>
          <Stack>
            <HStack spacing="0" gap="10px">
              <Text fontWeight="semibold">{t('name')}</Text>
              <InfoPopover description={t('input-unique-alert-name')} />
            </HStack>
            <Input
              value={msCallOptions.name}
              onInput={(e) => update({ name: e.currentTarget.value })}
              variant="filled"
              // bg="whiteAlpha.100"
              rounded="18px"
              h="55px"
            />
            {errors.name && (
              <Text color="orange.500" fontSize="sm">
                {errors.name.message}
              </Text>
            )}
          </Stack>

          <Stack>
            <HStack spacing="0" gap="10px">
              <Text fontWeight="semibold">{t('description')}</Text>
              <InfoPopover
                description={t('briefly-describe-the-purpose-of-this-alert')}
              />
            </HStack>
            <Input
              {...register('obj.description')}
              variant="filled"
              // bg="whiteAlpha.100"
              rounded="18px"
              h="55px"
            />
            {formState.errors?.obj?.description && (
              <Text color="orange.500" fontSize="sm">
                {formState.errors?.obj?.description.message}
              </Text>
            )}
          </Stack>

          <Stack>
            <HStack spacing="0" gap="10px">
              <Text fontWeight="semibold">{t('runningPeriod')}</Text>
              <InfoPopover description={t('specify-alerts-active-timeframe')} />
            </HStack>
            <Stack>
              <Stack flex="1">
                <Input
                  // type="date"
                  type="datetime-local"
                  variant="filled"
                  // bg="whiteAlpha.100"
                  rounded="18px"
                  h="55px"
                  value={validFrom}
                  onInput={(e) => {
                    update({
                      validFrom: moment(e.currentTarget.value)
                        .unix()
                        .toString(),
                    });
                  }}
                />
                {errors.validFrom && (
                  <Text color="orange.500" fontSize="sm">
                    {errors.validFrom.message}
                  </Text>
                )}
              </Stack>
              <Stack flex="1">
                <Input
                  // type="date"
                  type="datetime-local"
                  variant="filled"
                  // bg="whiteAlpha.100"
                  rounded="18px"
                  h="55px"
                  value={expiresAt}
                  onInput={(e) =>
                    update({
                      expiresAt: moment(e.currentTarget.value)
                        .unix()
                        .toString(),
                    })
                  }
                />
                {errors.expiresAt && (
                  <Text color="orange.500" fontSize="sm">
                    {errors.expiresAt.message}
                  </Text>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack flex="1" spacing="32px" {...props}>
          <Stack>
            <HStack spacing="0" gap="10px">
              <Text fontWeight="semibold">{t('telegram-id')}</Text>
              <InfoPopover
                description={t('enter-your-telegram-user-id-to-get-alerts')}
              />
            </HStack>
            <FormControl
              isInvalid={
                formState.errors?.obj?.telegram_id ||
                isTelegramOrWebhookMissingError
              }
            >
              <Input
                {...register('obj.telegram_id')}
                variant="filled"
                // bg="whiteAlpha.100"
                rounded="18px"
                h="55px"
              />
              <FormErrorMessage>
                {formState.errors?.obj?.telegram_id?.message}
              </FormErrorMessage>
            </FormControl>
          </Stack>
          {isTelegramOrWebhookMissingError && (
            <Text color="red.400">
              {isTelegramOrWebhookMissingError?.message}
            </Text>
          )}
          <Stack>
            <HStack spacing="0" gap="10px">
              <Text fontWeight="semibold">{t('webhook')}</Text>
              <InfoPopover
                description={t('provide-url-for-alerts-webhook-endpoint')}
              />
            </HStack>
            <FormControl
              isInvalid={
                formState.errors?.obj?.webhook ||
                isTelegramOrWebhookMissingError
              }
            >
              <Input
                {...register('obj.webhook')}
                variant="filled"
                // bg="whiteAlpha.100"
                rounded="18px"
                h="55px"
              />
              <FormErrorMessage>
                {formState.errors?.obj?.webhook?.message}
              </FormErrorMessage>
            </FormControl>
          </Stack>

          <Stack>
            <HStack spacing="0" gap="10px">
              <Text fontWeight="semibold">{t('success-notice')}</Text>
              <InfoPopover
                description={t(
                  'opt-for-a-confirmation-when-alert-runs-successfully'
                )}
              />
            </HStack>
            <Input
              {...register('obj.success_notice')}
              variant="filled"
              // bg="whiteAlpha.100"
              rounded="18px"
              h="55px"
            />
            {formState.errors?.obj?.success_notice && (
              <Text color="orange.500" fontSize="sm">
                {formState.errors?.obj?.success_notice.message}
              </Text>
            )}
          </Stack>
          <Stack>
            <HStack spacing="0" gap="10px">
              <Text fontWeight="semibold">{t('fail-notice')}</Text>
              <InfoPopover
                description={t(
                  'choose-to-be-notified-if-alert-execution-fails'
                )}
              />
            </HStack>
            <Input
              {...register('obj.fail_notice')}
              variant="filled"
              // bg="whiteAlpha.100"
              rounded="18px"
              h="55px"
            />
            {formState.errors?.obj?.fail_notice && (
              <Text color="orange.500" fontSize="sm">
                {formState.errors?.obj?.fail_notice.message}
              </Text>
            )}
          </Stack>
        </Stack>
      </HStack>
    </Stack>
  );
};
