/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import { BatchMultiSigCall } from '@kiroboio/fct-core';
import { useNetwork } from '@kiroboio/fct-sdk';
import { useCallback, useEffect, useState } from 'react';
import { useFCTStore } from '../FCTStore';
import { useMSCallOptionsStore } from './useMSCallOptions';
import { usePrepareFCT } from './usePrepareFCT';

export const useGetPaymentPerPayer = ({
  customGasPrice = 0,
  fastGasPrice = 0,
  slowGasPrice = 0,
}) => {
  const [converted, setConverted] = useState({
    custom: 0,
    customNow: 0,
    customCanRunNow: false,
    slow: 0,
    fast: 0,
  });

  const { data: networkData } = useNetwork();
  const {
    gasPrice: {
      fastest: { maxFeePerGas },
    },
    ethPriceInUsd,
    fctFees,
    kiroPrice,
    ethPenalty,
  } = networkData.raw;
  const usdRate = Number(ethPriceInUsd);
  const gasPrice = Math.round(Number(maxFeePerGas)).toString();
  const flowFees = {
    baseFeeBPS: fctFees.baseFeesBPS || '0',
    bonusFeeBPS: fctFees.bonusFeesBPS || '0',
  };

  const { msCallOptions } = useMSCallOptionsStore();
  const { createFCTData } = usePrepareFCT();

  const { nodes, edges } = useFCTStore((state) => ({
    nodes: state.nodes,
    edges: state.edges,
  }));

  const paymentPerPayerHandler = useCallback(
    async (custom = 0, slow = 0, fast = 0) => {
      const safeMaxGasPrice = Math.round(
        Number(msCallOptions.maxGasPrice)
      ).toString();
      const safeMsCallOptions = {
        ...msCallOptions,
        maxGasPrice: safeMaxGasPrice,
      };
      const fct = await createFCTData(safeMsCallOptions);

      if (!fct) return;
      const fctData = fct.data;
      const FCT = BatchMultiSigCall.from(fctData);

      const options = {
        ethPriceInKIRO: kiroPrice,
        penalty: ethPenalty,
        fees: flowFees,
      };
      return {
        custom: FCT.utils.getPaymentPerPayer({
          ...options,
          maxGasPrice: Math.round(custom).toString(),
        })[0],
        customNow: FCT.utils.getPaymentPerPayer({
          ...options,
          gasPrice,
          maxGasPrice: Math.round(custom).toString(),
        })[0],
        slow: FCT.utils.getPaymentPerPayer({
          ...options,
          maxGasPrice: Math.round(slow).toString(),
        })[0],
        fast: FCT.utils.getPaymentPerPayer({
          ...options,
          maxGasPrice: Math.round(fast).toString(),
        })[0],
        now: FCT.utils.getPaymentPerPayer({
          ...options,
          maxGasPrice: gasPrice,
        })[0],
      };
    },
    [
      createFCTData,
      nodes,
      edges,
      ethPenalty,
      flowFees,
      gasPrice,
      kiroPrice,
      msCallOptions,
    ]
  );

  useEffect(() => {
    if (customGasPrice > 0) {
      // const payment = paymentPerPayerHandler(customGasPrice, slowGasPrice, fastGasPrice)
      paymentPerPayerHandler(customGasPrice, slowGasPrice, fastGasPrice).then(
        (res) => {
          if (!res) return;
          const { custom, customNow, now, slow, fast } = res;
          setConverted((prev) => ({
            ...prev,
            custom: +(custom.largestPayment.nativeAmount || '0') * usdRate,
            slow: +(slow.largestPayment.nativeAmount || '0') * usdRate,
            fast: +(fast.largestPayment.nativeAmount || '0') * usdRate,
            customNow:
              +(customNow.largestPayment.nativeAmount || '0') * usdRate,
            customCanRunNow:
              +(custom.largestPayment.nativeAmount || '0') >=
              +(now.largestPayment.nativeAmount || '0'),
          }));
        }
      );
    }
  }, [customGasPrice, slowGasPrice, fastGasPrice, gasPrice]);

  return {
    paymentPerPayerHandler,
    converted,
  };
};
