import {
  Button,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import { useDeviceSelectors } from 'react-device-detect';
import { Info } from 'react-feather';

interface InfoProps {
  description: string;
  boxSize?: string;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const InfoPopover: FC<InfoProps> = ({
  description,
  boxSize,
  placement,
  onClick,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const t = useTranslations();
  return isMobile ? (
    <Popover
      placement={placement || 'bottom'}
      arrowSize={10}
      onClose={onClose}
      isOpen={isOpen}
    >
      <PopoverTrigger>
        <Button
          size="0"
          bg="none"
          _hover={{ bg: 'none' }}
          color="white"
          onClick={(e) => {
            if (onClick) onClick(e);
            onOpen();
          }}
        >
          <Icon
            as={Info}
            color="gray.500"
            boxSize={boxSize || '18px'}
            mr="2px"
          />
        </Button>
      </PopoverTrigger>

      <PopoverContent
        color="white"
        mt="0px"
        rounded="2xl"
        userSelect="none"
        border="none"
      >
        <PopoverArrow />
        <PopoverCloseButton mt="1" rounded="full" />
        {/* <PopoverHeader></PopoverHeader> */}
        <PopoverBody mr="10px">{description}</PopoverBody>
      </PopoverContent>
    </Popover>
  ) : (
    <Tooltip
      placement={placement || 'bottom'}
      label={description}
      aria-label={t('a-tooltip')}
    >
      <Icon as={Info} color="gray.500" boxSize={boxSize || '18px'} mr="2px" />
    </Tooltip>
  );
};
