// export const useNotifyForm = () => {};
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable func-names */
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { proxy, useSnapshot } from 'valtio';
import * as yup from 'yup';

type ErrorAlertType = '' | 'telegram_id' | 'webhook';

const alertsManager = proxy<{ errorAlertType: ErrorAlertType }>({
  errorAlertType: '',
});

const setErrorAlertType = (errorAlertType: ErrorAlertType) => {
  alertsManager.errorAlertType = errorAlertType;
};

export const alertsManagerFuncs = {
  setErrorAlertType,
};

export interface AlertCreateParamsDto {
  telegram_id: string;
  webhook: string;
  description: string;
  success_notice: string;
  fail_notice: string;
}

export type NotifyFormFields = {
  obj: AlertCreateParamsDto;
};

export const useNotifyForm = (values?: AlertCreateParamsDto) => {
  // const {
  //   fct: { alerts },
  // } = useAccount();
  const { errorAlertType } = useSnapshot(alertsManager);
  const t = useTranslations();

  const {
    setValue,
    getValues,
    setError,
    register,
    clearErrors,
    reset,
    watch,
    trigger,
    formState,
    handleSubmit,
  } = useForm<NotifyFormFields>({
    mode: 'all',
    defaultValues: {
      obj: {
        telegram_id: values?.telegram_id || '',
        webhook: values?.webhook || '',
        description: values?.description || '',
        success_notice: values?.success_notice || '',
        fail_notice: values?.fail_notice || '',
      },
    },
    resolver: yupResolver(
      yup.object({
        obj: yup
          .object({
            telegram_id: yup.string(),
            webhook: yup.string(),
          })
          .test(
            'either-or',
            t('you-must-provide-either-telegram-id-or-webhook'),
            function (data) {
              return Boolean(data.telegram_id || data.webhook);
            }
          ),
      })
    ),
  });

  const telegram_id = watch('obj.telegram_id');
  const webhook = watch('obj.webhook');

  useEffect(() => {
    trigger();
  }, [telegram_id, webhook, trigger]);

  useEffect(() => {
    if (errorAlertType === 'telegram_id') {
      setError('obj.telegram_id', { message: t('invalid-telegram-id') });
    } else if (errorAlertType === 'webhook') {
      setError('obj.webhook', { message: t('invalid-webhook') });
    } else {
      clearErrors(['obj.telegram_id', 'obj.webhook']);
    }
  }, [errorAlertType, setError, clearErrors, t]);

  return {
    setValue,
    getValues,
    setError,
    register,
    clearErrors,
    watch,
    formState,
    handleSubmit,
    reset,
  };
};
