export const NetworkGasPriceRangeSlide = () => <>NetworkGasPriceRangeSlide </>;
// import type { BoxProps, TextProps } from '@chakra-ui/react';
// import {
//   Box,
//   Center,
//   Divider,
//   HStack,
//   Slider,
//   SliderMark,
//   SliderTrack,
//   Stack,
//   Text,
// } from '@chakra-ui/react';
// import { useQuery } from '@tanstack/react-query';
// import { ethers } from 'ethers';
// import type { FC } from 'react';
// import { useGasTracker } from 'src/services/methods/useNetworkQuery';
//
// import { toMaxFixed } from '~/lib/utils/number';
//
// function calculateSelectedPercentage(
//   minGasPrice: number,
//   currentGasPrice: number,
//   maxGasPrice: number,
//   selectedGasPrice: number
// ): number {
//   // Calculate the range for each half of the slider (min to current, and current to max)
//   const leftRange = currentGasPrice - minGasPrice;
//   const rightRange = maxGasPrice - currentGasPrice;
//
//   let percentage: number;
//
//   if (selectedGasPrice <= currentGasPrice) {
//     // Selected gas price is between min and current
//     percentage = ((selectedGasPrice - minGasPrice) / leftRange) * 50;
//   } else {
//     // Selected gas price is between current and max
//     percentage = 50 + ((selectedGasPrice - currentGasPrice) / rightRange) * 50;
//   }
//
//   return percentage;
// }
//
// const Mark: FC<
//   { label: string; gwei: string; percentage: number } & TextProps
// > = ({ gwei, label, percentage, ...posProps }) => {
//   const labelStyles = {
//     fontSize: 'sm',
//     mt: '-1',
//     zIndex: 1,
//   };
//   return (
//     <SliderMark pos="relative" value={percentage} {...labelStyles}>
//       <Stack align="center" pos="absolute" top="0" left="0" {...posProps}>
//         <Center height="8px">
//           <Divider
//             orientation="vertical"
//             borderColor="white"
//             borderWidth="1px"
//           />
//         </Center>
//         <Stack align="center" spacing="-1">
//           <Text as="span" fontSize="xs" color="gray.500">
//             {label}
//           </Text>
//           <HStack spacing="1">
//             <Text as="span" fontWeight="semibold">
//               {toMaxFixed(+gwei, 0)}
//             </Text>{' '}
//             <Text as="span" fontSize="xs">
//               GWEI
//             </Text>
//           </HStack>
//         </Stack>
//       </Stack>
//     </SliderMark>
//   );
// };
//
// export const NetworkGasPriceRangeSlide: FC<
//   { selectedWei: string } & BoxProps
// > = ({ selectedWei, ...boxProps }) => {
//   const { gwei, wei } = useGasTracker();
//   const { data: selectedValue } = useQuery({
//     queryKey: ['selectedValue', selectedWei, wei],
//     queryFn: async () =>
//       calculateSelectedPercentage(+wei.min, +wei.now, +wei.max, +selectedWei),
//   });
//
//   const selectedValueClamped = Math.min(Math.max(selectedValue || 0, 4), 96);
//   return (
//     <Box pt="50px" pb="36px" {...boxProps}>
//       <Slider
//         aria-label="slider-ex-6"
//         pointerEvents="none"
//         bg="linear-gradient(270deg, #E53E3E 12.69%, #D79D2C 53.5%, #00FF66 100%)"
//         whiteSpace="nowrap"
//       >
//         <Mark label="Min" gwei={gwei.min} percentage={0} />
//         <Mark
//           label="Current"
//           gwei={gwei.now}
//           percentage={50}
//           transform="translateX(-50%)"
//         />
//         <Mark
//           label="Max"
//           gwei={gwei.max}
//           percentage={100}
//           transform="translateX(-100%)"
//         />
//         {selectedWei && (
//           <SliderMark
//             value={selectedValueClamped}
//             textAlign="center"
//             bg="#202831"
//             overflow="visible"
//             color="white"
//             mt="-16"
//             p="1"
//             px="4"
//             transform="translateX(-50%)"
//             rounded="xl"
//             pos="relative"
//           >
//             <Box
//               zIndex={-1}
//               pos="absolute"
//               left="50%"
//               bottom="0"
//               transform="translate(-50%, 50%) rotate(45deg)"
//               boxSize="10px"
//               bg="#202831"
//             />
//             <Stack spacing="-1" align="center">
//               <Text as="span" fontSize="xs">
//                 Your selection
//               </Text>
//               <HStack spacing="1">
//                 <Text as="span" fontWeight="semibold">
//                   {toMaxFixed(
//                     +ethers.utils.formatUnits(selectedWei, 'gwei'),
//                     0
//                   )}
//                 </Text>{' '}
//                 <Text as="span" fontSize="xs">
//                   GWEI
//                 </Text>
//               </HStack>
//             </Stack>
//           </SliderMark>
//         )}
//         <SliderTrack bg="linear-gradient(270deg, #E53E3E 12.69%, #D79D2C 53.5%, #00FF66 100%)" />
//       </Slider>
//     </Box>
//   );
// };
